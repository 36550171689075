let d = window.define;
d("@embroider/macros/es-compat2", function () {
  return esc(require("@embroider/macros/es-compat2"));
});
d("@embroider/macros/runtime", function () {
  return esc(require("@embroider/macros/runtime"));
});
d("@ember/legacy-built-in-components/components/_has-dom", function () {
  return esc(require("@ember/legacy-built-in-components/components/_has-dom"));
});
d("@ember/legacy-built-in-components/components/_internals", function () {
  return esc(require("@ember/legacy-built-in-components/components/_internals"));
});
d("@ember/legacy-built-in-components/components/checkbox", function () {
  return esc(require("@ember/legacy-built-in-components/components/checkbox"));
});
d("@ember/legacy-built-in-components/components/link-to", function () {
  return esc(require("@ember/legacy-built-in-components/components/link-to"));
});
d("@ember/legacy-built-in-components/components/text-field", function () {
  return esc(require("@ember/legacy-built-in-components/components/text-field"));
});
d("@ember/legacy-built-in-components/components/textarea", function () {
  return esc(require("@ember/legacy-built-in-components/components/textarea"));
});
d("@ember/legacy-built-in-components/index", function () {
  return esc(require("@ember/legacy-built-in-components/index"));
});
d("@ember/legacy-built-in-components/mixins/_target_action_support", function () {
  return esc(require("@ember/legacy-built-in-components/mixins/_target_action_support"));
});
d("@ember/legacy-built-in-components/mixins/text-support", function () {
  return esc(require("@ember/legacy-built-in-components/mixins/text-support"));
});
d("@ember/legacy-built-in-components/templates/empty", function () {
  return esc(require("@ember/legacy-built-in-components/templates/empty.hbs"));
});
d("@ember/legacy-built-in-components/components/checkbox", function () {
  return esc(require("@ember/legacy-built-in-components/components/checkbox"));
});
d("@ember/legacy-built-in-components/components/link-to", function () {
  return esc(require("@ember/legacy-built-in-components/components/link-to"));
});
d("@ember/render-modifiers/modifiers/did-insert", function () {
  return esc(require("@ember/render-modifiers/modifiers/did-insert"));
});
d("@ember/render-modifiers/modifiers/did-update", function () {
  return esc(require("@ember/render-modifiers/modifiers/did-update"));
});
d("@ember/render-modifiers/modifiers/will-destroy", function () {
  return esc(require("@ember/render-modifiers/modifiers/will-destroy"));
});
d("@ember/string/cache", function () {
  return esc(require("@ember/string/cache"));
});
d("@ember/string/index", function () {
  return esc(require("@ember/string/index"));
});
d("ember-cached-decorator-polyfill/index", function () {
  return esc(require("ember-cached-decorator-polyfill/index"));
});
d("@glimmer/component/-private/base-component-manager", function () {
  return esc(require("@glimmer/component/-private/base-component-manager"));
});
d("@glimmer/component/-private/component", function () {
  return esc(require("@glimmer/component/-private/component"));
});
d("@glimmer/component/-private/destroyables", function () {
  return esc(require("@glimmer/component/-private/destroyables"));
});
d("@glimmer/component/-private/ember-component-manager", function () {
  return esc(require("@glimmer/component/-private/ember-component-manager"));
});
d("@glimmer/component/-private/owner", function () {
  return esc(require("@glimmer/component/-private/owner"));
});
d("@glimmer/component/index", function () {
  return esc(require("@glimmer/component/index"));
});
d("discourse-common/config/environment", function () {
  return esc(require("discourse-common/config/environment"));
});
d("discourse-common/helpers/base-path", function () {
  return esc(require("discourse-common/helpers/base-path"));
});
d("discourse-common/helpers/base-url", function () {
  return esc(require("discourse-common/helpers/base-url"));
});
d("discourse-common/helpers/bound-i18n", function () {
  return esc(require("discourse-common/helpers/bound-i18n"));
});
d("discourse-common/helpers/component-for-collection", function () {
  return esc(require("discourse-common/helpers/component-for-collection"));
});
d("discourse-common/helpers/component-for-row", function () {
  return esc(require("discourse-common/helpers/component-for-row"));
});
d("discourse-common/helpers/d-icon", function () {
  return esc(require("discourse-common/helpers/d-icon"));
});
d("discourse-common/helpers/fa-icon", function () {
  return esc(require("discourse-common/helpers/fa-icon"));
});
d("discourse-common/helpers/get-url", function () {
  return esc(require("discourse-common/helpers/get-url"));
});
d("discourse-common/helpers/html-safe", function () {
  return esc(require("discourse-common/helpers/html-safe"));
});
d("discourse-common/helpers/i18n-yes-no", function () {
  return esc(require("discourse-common/helpers/i18n-yes-no"));
});
d("discourse-common/helpers/i18n", function () {
  return esc(require("discourse-common/helpers/i18n"));
});
d("discourse-common/lib/attribute-hook", function () {
  return esc(require("discourse-common/lib/attribute-hook"));
});
d("discourse-common/lib/avatar-utils", function () {
  return esc(require("discourse-common/lib/avatar-utils"));
});
d("discourse-common/lib/debounce", function () {
  return esc(require("discourse-common/lib/debounce"));
});
d("discourse-common/lib/deprecated", function () {
  return esc(require("discourse-common/lib/deprecated"));
});
d("discourse-common/lib/discourse-template-map", function () {
  return esc(require("discourse-common/lib/discourse-template-map"));
});
d("discourse-common/lib/dom-from-string", function () {
  return esc(require("discourse-common/lib/dom-from-string"));
});
d("discourse-common/lib/escape", function () {
  return esc(require("discourse-common/lib/escape"));
});
d("discourse-common/lib/get-owner", function () {
  return esc(require("discourse-common/lib/get-owner"));
});
d("discourse-common/lib/get-url", function () {
  return esc(require("discourse-common/lib/get-url"));
});
d("discourse-common/lib/helpers", function () {
  return esc(require("discourse-common/lib/helpers"));
});
d("discourse-common/lib/icon-library", function () {
  return esc(require("discourse-common/lib/icon-library"));
});
d("discourse-common/lib/later", function () {
  return esc(require("discourse-common/lib/later"));
});
d("discourse-common/lib/loader-shim", function () {
  return esc(require("discourse-common/lib/loader-shim"));
});
d("discourse-common/lib/object", function () {
  return esc(require("discourse-common/lib/object"));
});
d("discourse-common/lib/popular-themes", function () {
  return esc(require("discourse-common/lib/popular-themes"));
});
d("discourse-common/lib/raw-handlebars-helpers", function () {
  return esc(require("discourse-common/lib/raw-handlebars-helpers"));
});
d("discourse-common/lib/raw-handlebars", function () {
  return esc(require("discourse-common/lib/raw-handlebars"));
});
d("discourse-common/lib/raw-templates", function () {
  return esc(require("discourse-common/lib/raw-templates"));
});
d("discourse-common/lib/suffix-trie", function () {
  return esc(require("discourse-common/lib/suffix-trie"));
});
d("discourse-common/resolver", function () {
  return esc(require("discourse-common/resolver"));
});
d("discourse-common/utils/decorator-alias", function () {
  return esc(require("discourse-common/utils/decorator-alias"));
});
d("discourse-common/utils/decorators", function () {
  return esc(require("discourse-common/utils/decorators"));
});
d("discourse-common/utils/dom-utils", function () {
  return esc(require("discourse-common/utils/dom-utils"));
});
d("discourse-common/utils/escape-regexp", function () {
  return esc(require("discourse-common/utils/escape-regexp"));
});
d("discourse-common/utils/extract-value", function () {
  return esc(require("discourse-common/utils/extract-value"));
});
d("discourse-common/utils/handle-descriptor", function () {
  return esc(require("discourse-common/utils/handle-descriptor"));
});
d("discourse-common/utils/is-descriptor", function () {
  return esc(require("discourse-common/utils/is-descriptor"));
});
d("discourse-common/utils/macro-alias", function () {
  return esc(require("discourse-common/utils/macro-alias"));
});
d("discourse-common/utils/multi-cache", function () {
  return esc(require("discourse-common/utils/multi-cache"));
});
d("discourse-common/utils/watched-words", function () {
  return esc(require("discourse-common/utils/watched-words"));
});
d("dialog-holder/components/dialog-holder", function () {
  return esc(require("dialog-holder/components/dialog-holder"));
});
d("dialog-holder/services/dialog", function () {
  return esc(require("dialog-holder/services/dialog"));
});
d("dialog-holder/components/dialog-holder", function () {
  return esc(require("dialog-holder/components/dialog-holder"));
});
d("discourse-widget-hbs/helpers", function () {
  return esc(require("discourse-widget-hbs/helpers"));
});
d("ember-buffered-proxy/helpers", function () {
  return esc(require("ember-buffered-proxy/helpers"));
});
d("ember-buffered-proxy/mixin", function () {
  return esc(require("ember-buffered-proxy/mixin"));
});
d("ember-buffered-proxy/proxy", function () {
  return esc(require("ember-buffered-proxy/proxy"));
});
d("ember-cli-app-version/initializer-factory", function () {
  return esc(require("ember-cli-app-version/initializer-factory"));
});
d("ember-cli-app-version/utils/regexp", function () {
  return esc(require("ember-cli-app-version/utils/regexp"));
});
d("ember-load-initializers/index", function () {
  return esc(require("ember-load-initializers/index"));
});
d("ember-on-resize-modifier/modifiers/on-resize", function () {
  return esc(require("ember-on-resize-modifier/modifiers/on-resize"));
});
d("float-kit/components/d-button-tooltip", function () {
  return esc(require("float-kit/components/d-button-tooltip"));
});
d("float-kit/components/d-default-toast", function () {
  return esc(require("float-kit/components/d-default-toast"));
});
d("float-kit/components/d-float-body", function () {
  return esc(require("float-kit/components/d-float-body"));
});
d("float-kit/components/d-float-portal", function () {
  return esc(require("float-kit/components/d-float-portal"));
});
d("float-kit/components/d-inline-float", function () {
  return esc(require("float-kit/components/d-inline-float"));
});
d("float-kit/components/d-inline-menu", function () {
  return esc(require("float-kit/components/d-inline-menu"));
});
d("float-kit/components/d-inline-tooltip", function () {
  return esc(require("float-kit/components/d-inline-tooltip"));
});
d("float-kit/components/d-menu", function () {
  return esc(require("float-kit/components/d-menu"));
});
d("float-kit/components/d-popover", function () {
  return esc(require("float-kit/components/d-popover"));
});
d("float-kit/components/d-toast", function () {
  return esc(require("float-kit/components/d-toast"));
});
d("float-kit/components/d-toasts", function () {
  return esc(require("float-kit/components/d-toasts"));
});
d("float-kit/components/d-tooltip", function () {
  return esc(require("float-kit/components/d-tooltip"));
});
d("float-kit/lib/constants", function () {
  return esc(require("float-kit/lib/constants"));
});
d("float-kit/lib/d-menu-instance", function () {
  return esc(require("float-kit/lib/d-menu-instance"));
});
d("float-kit/lib/d-toast-instance", function () {
  return esc(require("float-kit/lib/d-toast-instance"));
});
d("float-kit/lib/d-tooltip-instance", function () {
  return esc(require("float-kit/lib/d-tooltip-instance"));
});
d("float-kit/lib/float-kit-instance", function () {
  return esc(require("float-kit/lib/float-kit-instance"));
});
d("float-kit/lib/get-scroll-parent", function () {
  return esc(require("float-kit/lib/get-scroll-parent"));
});
d("float-kit/lib/update-position", function () {
  return esc(require("float-kit/lib/update-position"));
});
d("float-kit/modifiers/apply-floating-ui", function () {
  return esc(require("float-kit/modifiers/apply-floating-ui"));
});
d("float-kit/modifiers/auto-close-toast", function () {
  return esc(require("float-kit/modifiers/auto-close-toast"));
});
d("float-kit/modifiers/close-on-click-outside", function () {
  return esc(require("float-kit/modifiers/close-on-click-outside"));
});
d("float-kit/modifiers/close-on-escape", function () {
  return esc(require("float-kit/modifiers/close-on-escape"));
});
d("float-kit/services/internal-tooltip", function () {
  return esc(require("float-kit/services/internal-tooltip"));
});
d("float-kit/services/menu", function () {
  return esc(require("float-kit/services/menu"));
});
d("float-kit/services/toasts", function () {
  return esc(require("float-kit/services/toasts"));
});
d("float-kit/services/tooltip", function () {
  return esc(require("float-kit/services/tooltip"));
});
d("select-kit/components/bulk-select-topics-dropdown", function () {
  return esc(require("select-kit/components/bulk-select-topics-dropdown"));
});
d("select-kit/components/categories-admin-dropdown", function () {
  return esc(require("select-kit/components/categories-admin-dropdown"));
});
d("select-kit/components/category-chooser", function () {
  return esc(require("select-kit/components/category-chooser"));
});
d("select-kit/components/category-drop-more-collection", function () {
  return esc(require("select-kit/components/category-drop-more-collection"));
});
d("select-kit/components/category-drop", function () {
  return esc(require("select-kit/components/category-drop"));
});
d("select-kit/components/category-drop/category-drop-header", function () {
  return esc(require("select-kit/components/category-drop/category-drop-header"));
});
d("select-kit/components/category-notifications-button", function () {
  return esc(require("select-kit/components/category-notifications-button"));
});
d("select-kit/components/category-row", function () {
  return esc(require("select-kit/components/category-row"));
});
d("select-kit/components/category-selector", function () {
  return esc(require("select-kit/components/category-selector"));
});
d("select-kit/components/color-palettes", function () {
  return esc(require("select-kit/components/color-palettes"));
});
d("select-kit/components/color-palettes/color-palettes-row", function () {
  return esc(require("select-kit/components/color-palettes/color-palettes-row"));
});
d("select-kit/components/combo-box", function () {
  return esc(require("select-kit/components/combo-box"));
});
d("select-kit/components/combo-box/combo-box-header", function () {
  return esc(require("select-kit/components/combo-box/combo-box-header"));
});
d("select-kit/components/composer-actions", function () {
  return esc(require("select-kit/components/composer-actions"));
});
d("select-kit/components/create-color-row", function () {
  return esc(require("select-kit/components/create-color-row"));
});
d("select-kit/components/dropdown-select-box", function () {
  return esc(require("select-kit/components/dropdown-select-box"));
});
d("select-kit/components/dropdown-select-box/dropdown-select-box-header", function () {
  return esc(require("select-kit/components/dropdown-select-box/dropdown-select-box-header"));
});
d("select-kit/components/dropdown-select-box/dropdown-select-box-row", function () {
  return esc(require("select-kit/components/dropdown-select-box/dropdown-select-box-row"));
});
d("select-kit/components/email-group-user-chooser-filter", function () {
  return esc(require("select-kit/components/email-group-user-chooser-filter"));
});
d("select-kit/components/email-group-user-chooser-row", function () {
  return esc(require("select-kit/components/email-group-user-chooser-row"));
});
d("select-kit/components/email-group-user-chooser", function () {
  return esc(require("select-kit/components/email-group-user-chooser"));
});
d("select-kit/components/filter-for-more", function () {
  return esc(require("select-kit/components/filter-for-more"));
});
d("select-kit/components/flair-chooser", function () {
  return esc(require("select-kit/components/flair-chooser"));
});
d("select-kit/components/flair-row", function () {
  return esc(require("select-kit/components/flair-row"));
});
d("select-kit/components/form-template-chooser", function () {
  return esc(require("select-kit/components/form-template-chooser"));
});
d("select-kit/components/future-date-input-selector", function () {
  return esc(require("select-kit/components/future-date-input-selector"));
});
d("select-kit/components/future-date-input-selector/future-date-input-selector-header", function () {
  return esc(require("select-kit/components/future-date-input-selector/future-date-input-selector-header"));
});
d("select-kit/components/future-date-input-selector/future-date-input-selector-row", function () {
  return esc(require("select-kit/components/future-date-input-selector/future-date-input-selector-row"));
});
d("select-kit/components/group-chooser", function () {
  return esc(require("select-kit/components/group-chooser"));
});
d("select-kit/components/group-dropdown", function () {
  return esc(require("select-kit/components/group-dropdown"));
});
d("select-kit/components/group-notifications-button", function () {
  return esc(require("select-kit/components/group-notifications-button"));
});
d("select-kit/components/icon-picker", function () {
  return esc(require("select-kit/components/icon-picker"));
});
d("select-kit/components/list-setting", function () {
  return esc(require("select-kit/components/list-setting"));
});
d("select-kit/components/mini-tag-chooser", function () {
  return esc(require("select-kit/components/mini-tag-chooser"));
});
d("select-kit/components/mini-tag-chooser/selected-collection", function () {
  return esc(require("select-kit/components/mini-tag-chooser/selected-collection"));
});
d("select-kit/components/multi-select", function () {
  return esc(require("select-kit/components/multi-select"));
});
d("select-kit/components/multi-select/format-selected-content", function () {
  return esc(require("select-kit/components/multi-select/format-selected-content"));
});
d("select-kit/components/multi-select/multi-select-filter", function () {
  return esc(require("select-kit/components/multi-select/multi-select-filter"));
});
d("select-kit/components/multi-select/multi-select-header", function () {
  return esc(require("select-kit/components/multi-select/multi-select-header"));
});
d("select-kit/components/multi-select/selected-category", function () {
  return esc(require("select-kit/components/multi-select/selected-category"));
});
d("select-kit/components/multi-select/selected-color", function () {
  return esc(require("select-kit/components/multi-select/selected-color"));
});
d("select-kit/components/none-category-row", function () {
  return esc(require("select-kit/components/none-category-row"));
});
d("select-kit/components/notifications-button", function () {
  return esc(require("select-kit/components/notifications-button"));
});
d("select-kit/components/notifications-button/notifications-button-row", function () {
  return esc(require("select-kit/components/notifications-button/notifications-button-row"));
});
d("select-kit/components/notifications-filter", function () {
  return esc(require("select-kit/components/notifications-filter"));
});
d("select-kit/components/notifications-filter/notifications-filter-header", function () {
  return esc(require("select-kit/components/notifications-filter/notifications-filter-header"));
});
d("select-kit/components/period-chooser", function () {
  return esc(require("select-kit/components/period-chooser"));
});
d("select-kit/components/period-chooser/period-chooser-header", function () {
  return esc(require("select-kit/components/period-chooser/period-chooser-header"));
});
d("select-kit/components/period-chooser/period-chooser-row", function () {
  return esc(require("select-kit/components/period-chooser/period-chooser-row"));
});
d("select-kit/components/pinned-button", function () {
  return esc(require("select-kit/components/pinned-button"));
});
d("select-kit/components/pinned-options", function () {
  return esc(require("select-kit/components/pinned-options"));
});
d("select-kit/components/search-advanced-category-chooser", function () {
  return esc(require("select-kit/components/search-advanced-category-chooser"));
});
d("select-kit/components/select-kit", function () {
  return esc(require("select-kit/components/select-kit"));
});
d("select-kit/components/select-kit/errors-collection", function () {
  return esc(require("select-kit/components/select-kit/errors-collection"));
});
d("select-kit/components/select-kit/select-kit-body", function () {
  return esc(require("select-kit/components/select-kit/select-kit-body"));
});
d("select-kit/components/select-kit/select-kit-collection", function () {
  return esc(require("select-kit/components/select-kit/select-kit-collection"));
});
d("select-kit/components/select-kit/select-kit-create-row", function () {
  return esc(require("select-kit/components/select-kit/select-kit-create-row"));
});
d("select-kit/components/select-kit/select-kit-filter", function () {
  return esc(require("select-kit/components/select-kit/select-kit-filter"));
});
d("select-kit/components/select-kit/select-kit-header", function () {
  return esc(require("select-kit/components/select-kit/select-kit-header"));
});
d("select-kit/components/select-kit/select-kit-none-row", function () {
  return esc(require("select-kit/components/select-kit/select-kit-none-row"));
});
d("select-kit/components/select-kit/select-kit-row", function () {
  return esc(require("select-kit/components/select-kit/select-kit-row"));
});
d("select-kit/components/select-kit/single-select-header", function () {
  return esc(require("select-kit/components/select-kit/single-select-header"));
});
d("select-kit/components/selected-choice-category", function () {
  return esc(require("select-kit/components/selected-choice-category"));
});
d("select-kit/components/selected-choice-color", function () {
  return esc(require("select-kit/components/selected-choice-color"));
});
d("select-kit/components/selected-choice", function () {
  return esc(require("select-kit/components/selected-choice"));
});
d("select-kit/components/selected-color", function () {
  return esc(require("select-kit/components/selected-color"));
});
d("select-kit/components/selected-flair", function () {
  return esc(require("select-kit/components/selected-flair"));
});
d("select-kit/components/selected-name", function () {
  return esc(require("select-kit/components/selected-name"));
});
d("select-kit/components/single-select", function () {
  return esc(require("select-kit/components/single-select"));
});
d("select-kit/components/tag-chooser-row", function () {
  return esc(require("select-kit/components/tag-chooser-row"));
});
d("select-kit/components/tag-chooser", function () {
  return esc(require("select-kit/components/tag-chooser"));
});
d("select-kit/components/tag-drop", function () {
  return esc(require("select-kit/components/tag-drop"));
});
d("select-kit/components/tag-drop/tag-drop-header", function () {
  return esc(require("select-kit/components/tag-drop/tag-drop-header"));
});
d("select-kit/components/tag-group-chooser", function () {
  return esc(require("select-kit/components/tag-group-chooser"));
});
d("select-kit/components/tag-notifications-button", function () {
  return esc(require("select-kit/components/tag-notifications-button"));
});
d("select-kit/components/tag-row", function () {
  return esc(require("select-kit/components/tag-row"));
});
d("select-kit/components/tags-intersection-chooser", function () {
  return esc(require("select-kit/components/tags-intersection-chooser"));
});
d("select-kit/components/timezone-input", function () {
  return esc(require("select-kit/components/timezone-input"));
});
d("select-kit/components/toolbar-popup-menu-options", function () {
  return esc(require("select-kit/components/toolbar-popup-menu-options"));
});
d("select-kit/components/toolbar-popup-menu-options/toolbar-popup-menu-options-heading", function () {
  return esc(require("select-kit/components/toolbar-popup-menu-options/toolbar-popup-menu-options-heading"));
});
d("select-kit/components/topic-chooser", function () {
  return esc(require("select-kit/components/topic-chooser"));
});
d("select-kit/components/topic-footer-mobile-dropdown", function () {
  return esc(require("select-kit/components/topic-footer-mobile-dropdown"));
});
d("select-kit/components/topic-notifications-button", function () {
  return esc(require("select-kit/components/topic-notifications-button"));
});
d("select-kit/components/topic-notifications-options", function () {
  return esc(require("select-kit/components/topic-notifications-options"));
});
d("select-kit/components/topic-row", function () {
  return esc(require("select-kit/components/topic-row"));
});
d("select-kit/components/user-chooser", function () {
  return esc(require("select-kit/components/user-chooser"));
});
d("select-kit/components/user-chooser/user-row", function () {
  return esc(require("select-kit/components/user-chooser/user-row"));
});
d("select-kit/components/user-notifications-dropdown", function () {
  return esc(require("select-kit/components/user-notifications-dropdown"));
});
d("select-kit/components/watched-words", function () {
  return esc(require("select-kit/components/watched-words"));
});
d("select-kit/mixins/plugin-api", function () {
  return esc(require("select-kit/mixins/plugin-api"));
});
d("select-kit/mixins/tags", function () {
  return esc(require("select-kit/mixins/tags"));
});
d("select-kit/mixins/utils", function () {
  return esc(require("select-kit/mixins/utils"));
});
d("select-kit/components/category-drop/category-drop-header", function () {
  return esc(require("select-kit/components/category-drop/category-drop-header"));
});
d("select-kit/components/color-palettes/color-palettes-row", function () {
  return esc(require("select-kit/components/color-palettes/color-palettes-row"));
});
d("select-kit/components/combo-box/combo-box-header", function () {
  return esc(require("select-kit/components/combo-box/combo-box-header"));
});
d("select-kit/components/create-color-row", function () {
  return esc(require("select-kit/components/create-color-row"));
});
d("select-kit/components/dropdown-select-box/dropdown-select-box-header", function () {
  return esc(require("select-kit/components/dropdown-select-box/dropdown-select-box-header"));
});
d("select-kit/components/dropdown-select-box/dropdown-select-box-row", function () {
  return esc(require("select-kit/components/dropdown-select-box/dropdown-select-box-row"));
});
d("select-kit/components/email-group-user-chooser-row", function () {
  return esc(require("select-kit/components/email-group-user-chooser-row"));
});
d("select-kit/components/flair-row", function () {
  return esc(require("select-kit/components/flair-row"));
});
d("select-kit/components/future-date-input-selector/future-date-input-selector-header", function () {
  return esc(require("select-kit/components/future-date-input-selector/future-date-input-selector-header"));
});
d("select-kit/components/future-date-input-selector/future-date-input-selector-row", function () {
  return esc(require("select-kit/components/future-date-input-selector/future-date-input-selector-row"));
});
d("select-kit/components/mini-tag-chooser/selected-collection", function () {
  return esc(require("select-kit/components/mini-tag-chooser/selected-collection"));
});
d("select-kit/components/multi-select", function () {
  return esc(require("select-kit/components/multi-select"));
});
d("select-kit/components/multi-select/format-selected-content", function () {
  return esc(require("select-kit/components/multi-select/format-selected-content"));
});
d("select-kit/components/multi-select/multi-select-filter", function () {
  return esc(require("select-kit/components/multi-select/multi-select-filter"));
});
d("select-kit/components/multi-select/multi-select-header", function () {
  return esc(require("select-kit/components/multi-select/multi-select-header"));
});
d("select-kit/components/multi-select/selected-category", function () {
  return esc(require("select-kit/components/multi-select/selected-category"));
});
d("select-kit/components/none-category-row", function () {
  return esc(require("select-kit/components/none-category-row"));
});
d("select-kit/components/notifications-filter/notifications-filter-header", function () {
  return esc(require("select-kit/components/notifications-filter/notifications-filter-header"));
});
d("select-kit/components/period-chooser/period-chooser-header", function () {
  return esc(require("select-kit/components/period-chooser/period-chooser-header"));
});
d("select-kit/components/period-chooser/period-chooser-row", function () {
  return esc(require("select-kit/components/period-chooser/period-chooser-row"));
});
d("select-kit/components/pinned-button", function () {
  return esc(require("select-kit/components/pinned-button"));
});
d("select-kit/components/select-kit/errors-collection", function () {
  return esc(require("select-kit/components/select-kit/errors-collection"));
});
d("select-kit/components/select-kit/select-kit-body", function () {
  return esc(require("select-kit/components/select-kit/select-kit-body"));
});
d("select-kit/components/select-kit/select-kit-collection", function () {
  return esc(require("select-kit/components/select-kit/select-kit-collection"));
});
d("select-kit/components/select-kit/select-kit-create-row", function () {
  return esc(require("select-kit/components/select-kit/select-kit-create-row"));
});
d("select-kit/components/select-kit/select-kit-filter", function () {
  return esc(require("select-kit/components/select-kit/select-kit-filter"));
});
d("select-kit/components/select-kit/select-kit-none-row", function () {
  return esc(require("select-kit/components/select-kit/select-kit-none-row"));
});
d("select-kit/components/select-kit/select-kit-row", function () {
  return esc(require("select-kit/components/select-kit/select-kit-row"));
});
d("select-kit/components/select-kit/single-select-header", function () {
  return esc(require("select-kit/components/select-kit/single-select-header"));
});
d("select-kit/components/selected-choice-category", function () {
  return esc(require("select-kit/components/selected-choice-category"));
});
d("select-kit/components/selected-choice", function () {
  return esc(require("select-kit/components/selected-choice"));
});
d("select-kit/components/selected-flair", function () {
  return esc(require("select-kit/components/selected-flair"));
});
d("select-kit/components/selected-name", function () {
  return esc(require("select-kit/components/selected-name"));
});
d("select-kit/components/single-select", function () {
  return esc(require("select-kit/components/single-select"));
});
d("select-kit/components/tag-chooser-row", function () {
  return esc(require("select-kit/components/tag-chooser-row"));
});
d("select-kit/components/tag-drop/tag-drop-header", function () {
  return esc(require("select-kit/components/tag-drop/tag-drop-header"));
});
d("select-kit/components/tag-row", function () {
  return esc(require("select-kit/components/tag-row"));
});
d("select-kit/components/toolbar-popup-menu-options/toolbar-popup-menu-options-heading", function () {
  return esc(require("select-kit/components/toolbar-popup-menu-options/toolbar-popup-menu-options-heading"));
});
d("select-kit/components/topic-notifications-button", function () {
  return esc(require("select-kit/components/topic-notifications-button"));
});
d("select-kit/components/topic-row", function () {
  return esc(require("select-kit/components/topic-row"));
});
d("select-kit/components/user-chooser/user-row", function () {
  return esc(require("select-kit/components/user-chooser/user-row"));
});
d("pretty-text/allow-lister", function () {
  return esc(require("pretty-text/allow-lister"));
});
d("pretty-text/censored-words", function () {
  return esc(require("pretty-text/censored-words"));
});
d("pretty-text/emoji", function () {
  return esc(require("pretty-text/emoji"));
});
d("pretty-text/emoji/data", function () {
  return esc(require("pretty-text/emoji/data"));
});
d("pretty-text/emoji/version", function () {
  return esc(require("pretty-text/emoji/version"));
});
d("pretty-text/guid", function () {
  return esc(require("pretty-text/guid"));
});
d("pretty-text/inline-oneboxer", function () {
  return esc(require("pretty-text/inline-oneboxer"));
});
d("pretty-text/mentions", function () {
  return esc(require("pretty-text/mentions"));
});
d("pretty-text/oneboxer-cache", function () {
  return esc(require("pretty-text/oneboxer-cache"));
});
d("pretty-text/oneboxer", function () {
  return esc(require("pretty-text/oneboxer"));
});
d("pretty-text/pretty-text", function () {
  return esc(require("pretty-text/pretty-text"));
});
d("pretty-text/sanitizer", function () {
  return esc(require("pretty-text/sanitizer"));
});
d("pretty-text/text-replace", function () {
  return esc(require("pretty-text/text-replace"));
});
d("pretty-text/upload-short-url", function () {
  return esc(require("pretty-text/upload-short-url"));
});
import "@discourse/ember-instantsearch/-embroider-implicit-modules.js";
import "ember-route-template/-embroider-implicit-modules.js";
import "@ember-compat/tracked-built-ins/-embroider-implicit-modules.js";
import "@embroider/router/-embroider-implicit-modules.js";
import "discourse-i18n/-embroider-implicit-modules.js";
import "discourse-markdown-it/-embroider-implicit-modules.js";
import "ember-modifier/-embroider-implicit-modules.js";
import "ember-qunit/-embroider-implicit-modules.js";
import "truth-helpers/-embroider-implicit-modules.js";
import "ember-cli-inject-live-reload/-embroider-implicit-modules.js";
import "custom-proxy/-embroider-implicit-modules.js";
import "@embroider/macros/-embroider-implicit-modules.js";
import "@ember/legacy-built-in-components/-embroider-implicit-modules.js";
import "@ember/optional-features/-embroider-implicit-modules.js";
import "@ember/render-modifiers/-embroider-implicit-modules.js";
import "@ember/string/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import "@ember/test-helpers/-embroider-implicit-modules.js";
import "@glimmer/tracking/-embroider-implicit-modules.js";
import "ember-cached-decorator-polyfill/-embroider-implicit-modules.js";
import "ember-template-imports/-embroider-implicit-modules.js";
import "ember-cli-babel/-embroider-implicit-modules.js";
import "@glimmer/component/-embroider-implicit-modules.js";
import "discourse-common/-embroider-implicit-modules.js";
import "admin/-embroider-implicit-modules.js";
import "dialog-holder/-embroider-implicit-modules.js";
import "discourse-widget-hbs/-embroider-implicit-modules.js";
import "discourse-plugins/-embroider-implicit-modules.js";
import "ember-buffered-proxy/-embroider-implicit-modules.js";
import "ember-cli-app-version/-embroider-implicit-modules.js";
import "ember-test-selectors/-embroider-implicit-modules.js";
import "ember-production-deprecations/-embroider-implicit-modules.js";
import "ember-cli-deprecation-workflow/-embroider-implicit-modules.js";
import "ember-cli-progress-ci/-embroider-implicit-modules.js";
import "broccoli-asset-rev/-embroider-implicit-modules.js";
import "ember-cli-terser/-embroider-implicit-modules.js";
import "ember-cli-sri/-embroider-implicit-modules.js";
import "ember-source/-embroider-implicit-modules.js";
import "ember-decorators/-embroider-implicit-modules.js";
import "ember-exam/-embroider-implicit-modules.js";
import "ember-load-initializers/-embroider-implicit-modules.js";
import "ember-on-resize-modifier/-embroider-implicit-modules.js";
import "float-kit/-embroider-implicit-modules.js";
import "loader.js/-embroider-implicit-modules.js";
import "select-kit/-embroider-implicit-modules.js";
import "pretty-text/-embroider-implicit-modules.js";
import esc from "../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";